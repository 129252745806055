@import '../../styles/customMediaQueries.css';
.container {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 400px));
  max-width: 90%;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  & h1 {
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: var(--marketplaceColor);
  }

  & img {
    max-width: 350px;
  }

  & p {
    margin: 2rem 0;
    font-weight: normal;
    font-size: 0.975rem;
    font-weight: 500;
    color: #888;
    line-height: 1.4;
  }
  & button {
    width: 100%;
    display: inline-block;
    border: 1px solid #eee;
    padding: 0.75rem 2rem;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    letter-spacing: 1px;
    background-color: var(--marketplaceColor);
    cursor: pointer;
    transition: all 200ms ease;
    margin-bottom: 0.5rem;
    &:hover,
    &:focus {
      transform: scale(1.02);
      background-color: var(--marketplaceColorDark);
    }
  }

  @media (--viewportMedium) {
    max-width: 50%;
  }
}

.loadingContainer {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.subscribedSuccessful {
  text-align: center;
}
button.cancelButton {
  background: white;
  color: var(--marketplaceColor);
}
button.cancelButton:hover {
  background: #eee;
  color: var(--marketplaceColor);
  border: none;
}
.detailsUpdated {
  border: 1px solid var(--successColor);
  background-color: var(--successColor);
  padding: 0.15rem 0.25rem;
  color: white;
  margin: 1rem 0.25rem;
  border-radius: 7px;
  text-align: center;
}
.subHeading {
  margin: 0;
  line-height: 1.4;
}
