.grey {
  color: #959595 !important;
}
.black {
  color: black;
}
.white {
  color: white;
}
.red {
  color: red;
}

.typeLight {
  font-weight: 300;
}
// GLOBAL
.inline-block {
  display: inline-block;
}
.underline {
  text-decoration: underline;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.hp-white {
  background-color: white;
}

.strong {
  font-weight: 500;
}

.add-stroke {
  stroke: black;
  stroke-width: 20;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
  color: white;
  -webkit-text-stroke: 2.5px black;
}

// BUTTONS
$btn-small-size: 0.8em 1em;
$btn-large-size: 1.5em 3em;

// PADDING
$space-xsmall: 5px;
$space-small: 10px;
$space-smedium: 20px;
$space-medium: 30px;
$space-large: 40px;
$space-xlarge: 50px;
$space-xxlarge: 50px;

// ALL CAPS
.caps {
  text-transform: uppercase;
}

// CENTERED
.centered {
  text-align: center;
}
