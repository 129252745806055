.root {
  /* border: 1px solid lightgray; */
  padding: 0.75rem 0 0 0;
  &:not(:last-child) {
    border-bottom: none;
  }
}

.heading,
.value {
  margin: 0;
}

.value {
  margin-top: 0.25rem;
  line-height: 1.4;
  font-size: 0.875rem;
  color: hsl(0, 0%, 40%);
}

.centered {
  /* text-align: center; */
}
