@mixin breakpoint($point) {
  @if $point == xs {
    @media all and (max-width: 540px) { @content ; }
  }
  @else if $point == sm {
    @media all and (max-width: 768px) { @content ; }
  }
  @else if $point == md {
    @media all and (min-width: 992px) { @content ; }
  }
  @else if $point == lg {
    @media all and (min-width: 1200px) { @content ; }
  }
  @else if $point == xlg {
    @media all and (min-width: 1800px) { @content ; }
  }
  @else if $point == ipad-portrait {
    @media all and (max-width: 768px) { @content ; }
  }
}

@mixin ease-in {
  transition: all 0.2s ease;
}

@mixin ease-in-out {
  transition: all .4s ease-in-out;
}

@mixin fade-in {
  animation: fadein 2s;
}

@mixin fade-in-one {
  animation: fadein 0.5s;
}

@mixin pop-in($time) {
  @if $time == 2 {
    animation: popin 2s;
  } @else if $time == 3 {
    animation: popin 3s;
  } @else if $time == 4 {
    animation: popin 4s;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}