.authCon {
  height: 100%;
  @include breakpoint(sm) {
    width: 100%;
  }
  .sideImg {
    height: 100%;
    background-image: url("../../assets/auth-bg.jpg");
    background-size: cover;
    @include breakpoint(sm) {
      display: none;
    }
  }
}
