.hWalkthrough {
  h1 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-family: 'Cormorant', serif;
  }
  .flexRowForm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    div {
      width: 30%;
    }
  }
}

.tagsContainer {
  width: 100%;

  .tags {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    gap: 8px;
    margin-bottom: 16px;

    [data-list-item] {
      display: inline;
      margin-right: 8px;
      background-color: white;
      border: 1px solid #e7e7e7;
      border-radius: 24px;
      padding: 5px 15px;
      color: #4a4a4a;
      margin: 0;
    }
  }
}

.walkthroughModal {
  min-width: 500px;
  .tags {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    li,
    [data-list-item] {
      display: inline;
      margin-right: 8px;
      background-color: white;
      border: 1px solid #e7e7e7;
      border-radius: 24px;
      padding: 12px;
      color: #4a4a4a;
      margin-bottom: 16px;
      &:hover,
      &.selected {
        cursor: pointer;
        background-color: #e6c200;
        color: white;
        @include ease-in-out;
      }
    }
  }
}
