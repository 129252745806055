@import '../../styles/propertySets.css';

.root {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  margin-top: 1rem;
}

.imagesField {
  flex-shrink: 0;
  margin: 0;
}

.thumbnail {
  margin: 24px 0 0 0;

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;
    /* margin-bottom: 0; */

    &:nth-child(even) {
      margin: 0 0 24px 0;
      /* margin-bottom: 0; */
    }
  }
}

.addImageWrapper {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 0 24px 0;
    margin-top: 0.25rem;
  }
}
.addImageWrapperBanner {
  float: left;
  position: relative;
  width: 100%;
  margin: 24px 0 0 0;
  overflow: hidden;

  &::after {
    content: '.';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  @media (--viewportLarge) {
    width: 100%;
    margin: 0 0 24px 0;
  }
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectRatioWrapper {
  padding-bottom: calc(100% * (2 / 3));
}
.aspectRatioWrapperPrimary {
  padding-bottom: calc(100% * (4 / 4));
}
.aspectRatioWrapperBanner {
  padding-bottom: calc(100% * (1 / 3));
}

.addImage {
  /* Layout */
  /* Maintain aspect ratio */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;

  /* Colors */
  background-color: var(--matterColorLight);

  @media (--viewportLarge) {
    background-color: var(--matterColorBright);
  }

  border-style: dashed;
  border-color: var(--matterColorNegative);
  border-width: 2px;
  border-radius: 2px;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  &:hover {
    border-color: var(--matterColorAnti);
  }
}

.chooseImageText {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chooseImage {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.imageTypes {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.addImageInput {
  display: none;
}

.imageRequiredWrapper {
  width: 100%;
  clear: both;
}

.tip {
  @apply --marketplaceH5FontStyles;
  flex-shrink: 0;
  color: var(--matterColorAnti);
  margin-top: 24px;
  margin-bottom: 60px;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  flex-shrink: 0;
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
/* /// */
.container {
  width: 49%;
  border-style: dashed;
  border: dashed 2px solid lightgray;
  &:hover {
    border: dashed 2px solid #bcbbbb;
  }
}

.bannerContainer {
  flex-shrink: 0;
  margin: 0;
  margin-top: 10px;
}
.banner {
  width: calc(100% - 0px);
  margin: 0 24px 24px 0;
  height: 150px;
  @media (--viewportLarge) {
    width: calc(50% - 12px);
    margin: 0 24px 24px 0;

    &:nth-child(even) {
      margin: 0 0 24px 0;
    }
  }
}
.bannerText {
  @apply --marketplaceH4FontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  margin: 10px 0px;
}
.bannerImageContainer {
  display: flex;
  flex-wrap: wrap;
  height: 50%;
}
.bannerImage {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.bannerTest {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0.5;
  border: 2px solid gray;
}
.tick {
  position: relative;
  margin-bottom: 70px;
}
.image {
  width: 20%;
  margin: 0.5rem;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.cropcontainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoomrange {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: var(--marketplaceColor);
  width: 100%;
}

.zoomrange::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--marketplaceColor);
  background: var(--marketplaceColor);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoomrange::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid var(--marketplaceColor);
  background: var(--marketplaceColorDark);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  /* box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16); */
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  /* box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16); */
}
.selectImageAreaModalContent {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  height: 100%;
  margin-top: 10rem;
  @media (--viewportMedium) {
    flex-basis: 800px;
    min-height: auto;
    height: auto;
  }
}
.containerPrimary {
  margin: 10px 0px;
}
