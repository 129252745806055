html,
body {
  max-width: 100%;
}
html {
  overflow-x: hidden;
}

.gapped-flex {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  justify-content: center;
}

[data-disabled='true'] {
  pointer-events: none;
}

.flexRowForm {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  & > * {
    flex: 1;
  }

  @media (max-width: 768px) {
    display: block;
    & > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.fullHR {
  width: 100% !important;
  opacity: 0.15;
}
.hrThick {
  border: 5px solid transparent;
  background-color: #e6c200;
  height: 2px;
}
.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}
.img-responsive {
  display: block;
  max-width: 100%;
  max-height: 100%;
  border: 4px white solid;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: white !important;
}
.fullInput,
.fullTextArea {
  height: 53px;
  background: #fafafa;
  border: 1px solid #979797;
  border-radius: 6px;
  padding: 0 15px;
  margin-top: 16px;
  margin-right: 8px;
}

.pt-75 {
  padding-top: 0.75rem;
}

.fullTextArea {
  height: 125px;
  padding-top: 16px;
  margin: 0;
  padding: 0px;
}
.hwInput,
.hwTextArea {
  background: #fafafa;
  border: 1px solid #979797;
  border-radius: 6px;
  height: 50px;
  padding-left: 16px;
  margin-top: 16px;
  width: 100%;
}
.hwTextArea {
  padding-left: 0;
}
.highlighted-top {
  display: flex;
}
.flexRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
//GLOBAL
@import './variables.scss';
@import './utilities.scss';
@import './mixins.scss';
@import './_grid.scss';

// PAGES
@import './pages/homepage.scss';
@import './pages/static.scss';
@import './pages/auth.scss';
@import './pages/healerLanding.scss';
@import './pages/healerMain.scss';
@import './pages/modSinglePage.scss';
@import './pages/booking.scss';
@import './pages/walkthrough.scss';
@import './components/healerCard.scss';

.btn[data-disabled='true'] {
  background-color: lightslategray;
  pointer-events: none;
  cursor: not-allowed;
}

.btn {
  background-color: #e6c200;
  padding: 12px 32px;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid transparent;
  color: #000;
  cursor: pointer;
  text-decoration: none;
  @include ease-in-out;
  &.greyOutline {
    background-color: white;
    border: 1px solid #e7e7e7;
    color: #4a4a4a;
    &:hover {
      background-color: rgb(207, 202, 202);
    }
  }
  &.greyOutlineRounded {
    background-color: white;
    border: 1px solid #e7e7e7;
    color: #4a4a4a;
    border-radius: 100px;
    margin: 0 16px 16px 0;
    &:hover,
    &.selected {
      background-color: #e6c200;
      color: white;
      cursor: pointer;
      @include ease-in-out;
    }
    &:active {
      background-color: #e6c200;
      color: white;
    }
  }
  &.green {
    background-color: #65c87a;
    border: 1px solid #65c87a;
    color: white;
    &:hover {
      background-color: rgb(2, 175, 17);
    }
  }
  &.btn-small {
    padding: 4px 8px;
    font-size: 14px;
    white-space: nowrap;
  }
  &:hover {
    background-color: #8f7a05;
    text-decoration: none;
  }
}
.btn-large-outline {
  text-decoration: none;
  width: 100%;
  text-align: center;
  background: #ffffff;
  border: 1px dashed #979797;
  border-radius: 6px;
  height: 50px;
  margin-bottom: 16px;
  &:hover {
    background: #eeeeee;
    border: 1px dashed #8b8888;
    transition: all ease-in-out 0.1s;
    cursor: pointer;
    text-decoration: none;
  }
}

// DELETE ME WHEN DONE
.doneBadge {
  background-color: #00e60c;
  color: rgb(0, 116, 35);
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 500;
  border: 1px solid transparent;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #00ce45;
  }
}
.fakeParentContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.fakeHealerContainer {
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
.mapboxgl-canvas-container {
  width: 70vw !important;
}

// LANDING PAGE MOBILE Styles - NEEDS REFACTOR
///////////////////////////////////////////////
//////////////////////////////////////////////

.landing {
  @include breakpoint(sm) {
    h1 {
      font-size: 3.25em;
      padding-right: 3rem;

      line-height: 1;
    }
    .landing-title {
      // width: 340px;
      font-size: 2.5em;
      line-height: 40px;
      &.push-bottom-medium {
        padding-bottom: 0px !important;
      }
    }
    .hrThick {
      display: none;
    }
  }
}
.btnGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 55%;
  @include breakpoint(sm) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.butSection {
  display: flex;
  justify-content: space-between;
  width: 55%;
  @include breakpoint(sm) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  a {
    @include breakpoint(sm) {
      margin-bottom: 18px;
    }
  }
}
.hideMobile {
  @include breakpoint(sm) {
    display: none;
  }
}
.contentMobile {
  .content {
    padding: 29px 125px;
    @include breakpoint(sm) {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  input::placeholder {
    font-size: 1em;
    font-weight: 400;
  }
  .lastName {
    padding-top: 16px;
  }
}
