@import '../../styles/propertySets.css';

.root {
}

.password {
  @apply --marketplaceModalPasswordMargins;
  margin-bottom: 24px;
}

.submit {
  margin-top: 1rem;
}
