.root {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.submitButton {
  margin-bottom: 1rem;
}

.linkButton {
  text-decoration: none;
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.modalText {
  margin-top: 0;
  line-height: 1.6;
  margin-bottom: 0.75rem;
}
.subscriptionSuccess{
  text-align: center;
  margin: 2rem 0;
}