.modSinglePage {
  .modHeader {
    text-align: center;
    img {
      height: 250px;
    }
    .title {
      padding: 34px;
      padding-bottom: 0;
      background-color: rgb(255, 255, 255);
      font-family: 'Comorant', serif;
      font-size: 52px;
      line-height: 1.25em;
      font-weight: 400;
      margin-top: -75px;
      h1 {
        background: white;
        z-index: 999;
        position: relative;
        width: max-content;
        padding: 16px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}

.modWholePage {
  h1 {
    font-family: 'Comorant', serif;
    font-weight: 300;
  }
  .offBox {
    cursor: pointer;
    @include ease-in-out;
    color: black;
    &:hover {
      opacity: 0.5;
    }
    a {
      color: black;
      &:hover {
        text-decoration: none;
      }
    }
    h2 {
      font-size: 1.1em;
      margin: 0;
    }
    p {
      font-size: 0.75em;
      margin: 0;
    }
  }
}

.healerSearch {
  .row {
    margin: 0 !important;
    padding: 0 !important;
  }
  h1 {
    font-family: 'Comorant', serif;
    font-weight: 300;
  }
}
