.root {
  /* Expand to available space */
  flex-grow: 1;

  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout HERE */
  display: flex;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 1px;
  }
}

.features {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-bottom: 32px;
  }
}

.error {
  color: var(--failColor);
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    /* display: inline-block; */
    /* width: 241px; */
    /* margin-top: 100px; */
    margin-top: 50px;
  }
}

.box {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 10px;
}

.controls {
  align-self: center;
}

.close,
.edit {
  cursor: pointer;
  font-size: 1.5rem;
  background-color: whitesmoke;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 1px solid rgb(222, 220, 220);
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: rgb(222, 220, 220);
  }
}

.edit {
  margin-top: 0.5rem;
}

.flex {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: space-between;
  margin-bottom: 0.75rem;
  & p {
    &:first-child {
      font-size: 0.975rem;
      color: black;
      font-weight: 600;
      letter-spacing: 0.5px;
    }
    &:last-child {
      font-size: 0.9rem;
      line-height: 1.4;
    }
    margin: 0;
  }
}

.button {
  margin-top: 2rem;
}

.minWidth {
  padding: 1rem;
  width: 400px;
  @media all and (max-width: 768px) { width: 100%; }
}

.icon {
  font-size: 0.8rem;
}

.text {
  margin-top: 0;
  line-height: 1.6;
}

.deleteButton {
  margin-top: auto;
}
