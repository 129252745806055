.static-page {
  .static-img {
    width: 100%;
    box-shadow: 0px 8px 38px 5px rgba(0, 0, 0, 0.09);
    &:hover { opacity: 0.6;}
    @include ease-in-out;
  }
  a {
    font-size: 18px;
    color:rgb(0, 17, 250) !important;
  }
}
