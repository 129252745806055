@import '../../styles/propertySets.css';

.root {
  & p {
    @apply --marketplaceH4FontStyles;
  }
  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }
  & .subHeading{
    margin-top: 0;
    margin-bottom: 55px;

    @media (--viewportMedium) {
      margin-top: 0;
      margin-bottom: 54px;
    }
  }
  & .lastUpdated {
    /* @apply --marketplaceBodyFontStyles; */
    margin-top: 0;
   font-style: italic;
  }
  & .listContainer{

  padding-left: 0.2rem;
  
  }
  
  & .listHeading{
    margin-left: 0;
    /* list-style-type:decimal; */
    font-weight: 600;
    /* padding-left: 1.2rem; */
    margin-top: 1.5rem;

  }
  & .subListHeading{

    list-style-type:none;
    font-weight: 600;
    margin-top: 1rem;
  }
  & .subListContainer{
    margin-left: 0.8rem;
    /* margin-top: 1rem; */
  & p{
    margin-top: 0;
    margin-left: 1rem;


  }
  }



  .outerList{
    margin-left: 0;
    list-style-type:decimal

  }
  .innerList{
    margin-left: 0;
    list-style-type:decimal
  }
  & li{
    font-weight: var(--fontWeightMedium);
    font-size: 15px;
      line-height: 24px;
  }
}
