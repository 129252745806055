.hMain {
  .healerMainProfileHeader .react-parallax-content {
    min-height: 475px;
  }
  .healerImg {
    height: 100%;
  }
  .healerProfileContent {
    color: white;
    position: absolute;
    padding: 0 8%;
    display: flex;

    width: 100%;
    align-items: center;
    bottom: 48px;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
    }
    ul {
      display: inline;
      margin: 0;
      padding: 0;
      li {
        display: inline;
        margin-right: 8px;
      }
    }
    h1 {
      line-height: 1;
      font-family: 'Comorant', serif;
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 100;
      @media (min-width: 768px) {
        font-size: 68px;
      }
    }
  }
  .healerProfileSection {
    padding-bottom: 4px;
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: #e6c200;
    }
    .tags {
      display: flex;
      flex-basis: 100%;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      li {
        display: inline;
        margin-right: 8px;
        background-color: white;
        border: 1px solid #e7e7e7;
        border-radius: 24px;
        padding: 8px;
        color: #4a4a4a;
        margin-bottom: 16px;
      }
    }
  }
  .healerProfileOfferings {
    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding-bottom: 24px;
    .header {
      background-color: #e6c200;
      text-align: center;
      color: black;
      font-weight: 600;
      padding: 4px;
      border-top-right-radius: 8px 8px;
      border-top-left-radius: 8px 8px;
    }
    ul {
      margin: 0;
      padding: 16px;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 16px;
        padding-top: 16px;
        gap: 0.125rem;
        .offerringInfo {
          h4 {
            color: black;
            font-weight: 600;
            padding: 0;
            margin: 0;
          }
          .price {
            color: #959595;
            padding: 0;
            margin: 0;
          }
          p {
            color: black;
            font-size: 12px;
            line-height: 24px;
            font-weight: 300;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
  .reviewList {
    .review {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      img {
        margin-top: 0px;
      }
      .reviewContent {
        padding-left: 16px;
      }
      h4 {
        font-weight: 600;
        font-size: 16px;
        font-weight: 600;
        color: black;
      }
      p {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}
