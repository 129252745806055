.root {
}

.input {
  border-bottom-color: var(--marketplaceColor);
  border: 1px solid #979797;
  padding: 16px;
  border-radius: 4px;
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
}

.inputError {
  /* border-bottom-color: var(--failColor); */
}

.textarea {
}
