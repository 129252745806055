.paymentForm *,
.paymentForm *:before,
.paymentForm *:after {
  box-sizing: border-box;
}

.paymentForm {
  max-width: 500px;
  margin: 80px auto;
  background-color: #fff;
  padding: 2rem;
  border: 1px solid #eee;
  border-radius: 10px;
}

.paymentForm label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
}

.paymentForm button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  /* text-transform: uppercase; */
  letter-spacing: 0.025em;
  background-color: var(--marketplaceColor);
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  display: block;
  width: 100%;
}

.paymentForm button:hover {
  color: #fff;
  cursor: pointer;
  background-color: var(--marketplaceColorDark);
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.paymentForm button[disabled] {
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.paymentForm input {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
  transition: all 150ms ease;
}

.paymentForm input::placeholder {
  color: #aab7c4;
  color: hsl(233, 26%, 55%);
}

.paymentForm input:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
}

.paymentForm .result,
.paymentForm .error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.paymentForm .error {
  color: #e4584c;
}

.paymentForm .result {
  color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal {
  padding: 0;
}

@media screen and (max-width: 400px) {
  .paymentForm {
    max-width: 300px;
    margin: 80px auto;
    background-color: #fff;
    padding: 2rem;
    border: 1px solid #eee;
    border-radius: 10px;
  }
}

@media screen and (max-width: 568px) {
  .paymentForm {
    max-width: 400px;
    margin: 80px auto;
    background-color: #fff;
    padding: 2rem;
    border: 1px solid #eee;
    border-radius: 10px;
  }
}

.spacer {
  margin: 5px;
}

.stripeError {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: red;
  margin-left: 0.5rem;
}

.root {
  width: 100%;
}

.loadingRoot {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.error {
  color: red;
}

.genericStripeError {
  color: red;
  line-height: 1.2;
  max-width: 500px;
  display: inline-block;
}
