.hLanding {
  width: 100%;
  background-color: white;
  color: black;
  h1 {
    font-family: 'Comorant', serif;
    font-size: 52px;
    line-height: 1.25em;
    font-weight: 400;
  }
  .hLandingHead  {
    display: flex;
    background-color: #FCFBFC;
  }
  .content {
    padding: 10% 8%;
    max-width: 700px;
  }
  .contentImg {
    width: 85%;
    margin-left: 18%;
  }
  .earn {
    padding: 16% 0;
  }
}
.landingTestimonial {
  background-color: black;
  padding: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  .testimonialText {
    color:  white;
  }
  h3 {
    font-size: 20em;
    position: absolute;
    opacity: 0.2;
    font-family: 'Poppins', serif;
    top: 100px;
  }
  h4 {
    font-weight: 600;
    font-size: 24px;
    padding-top: 24px;
  }
  img {
    width: 100%;
    height: auto;
  }
}
