.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* padding: 11px 24px 0 24px; */
  padding-top: 11px;
  padding-bottom: 11px;

  @media (min-width: 1024px) {
    /* padding: 88px 36px 82px 59px; */
    padding-top: 88px;
    /* padding-bottom: 88px; */
  }

  /* @media (min-width: 1128px) {
    padding: 88px calc((100% - 1056px) / 2) 82px 59px;
  } */
}
.tip {
  font-size: 1.2rem;
  color: #767474;
  margin-top: 0.5rem;
}
.form {
  flex-grow: 1;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.panelTitle {
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  font-family: 'Cormorant', serif;
}

.panelDescription {
  line-height: 1.4;
}

.goBackContainer {
  margin-top: 1.5rem;
}
