.healerCard {
  padding-top: 24px;
  @include breakpoint(sm) {
    width: 88vw;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
  .hcLeftAligned {
    display: flex;
    flex-direction: row;
    width: auto;
    // background-color: rgb(75, 191, 42);
    @media (min-width: 768px) {
      // background-color: #f00;
      width: clamp(300px, 400px, 1200px); // width: max-content;
    }

    h6 {
      color: #959595;
      font-size: 11px;
      font-weight: 500;
      text-transform: capitalize;
      word-break: break-word;
      hyphens: auto;
      // line-height: 0px;
      padding-top: 8px;
    }
    h4 {
      color: black;
      font-size: 21px;
      font-weight: 500;
      line-height: 12px;
      word-break: break-word;
      hyphens: auto;
      line-height: 1.4;
    }
    .h4 {
      color: black !important;
      font-size: 21px !important;
      font-weight: 500 !important;
      line-height: 12px !important;
      word-break: break-word !important;
      -webkit-hyphens: auto !important;
      hyphens: auto !important;
    }
    .flexCardLeft {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 1px;
      @media (min-width: 768px) {
        padding-left: 16px;
      }

      justify-content: space-between;
      word-break: break-all;
      hyphens: auto;

      p {
        line-height: 1.4 !important;
        font-size: 12px;
        line-height: 4px;
        font-weight: 500;
        color: black;
        word-break: break-all;
        hyphens: auto;
      }
      h3 {
        line-height: 12px;
        color: #e6c200;
        font-weight: 500;
        font-size: 22px;
        word-break: break-all;
        hyphens: auto;
      }
    }
  }
  .hcBottomAligned {
    display: flex;
    flex-direction: column;
    width: max-content;
    h6 {
      color: #959595;
      font-size: 11px;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 0px;
      padding-top: 8px;
    }
    h4 {
      color: black;
      font-size: 21px;
      font-weight: 500;
      line-height: 12px;
    }
    .flexCardHor {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        font-size: 12px;
        line-height: 12px;
        font-weight: 500;
        color: black;
      }
      h3 {
        line-height: 12px;
        color: #e6c200;
        font-weight: 500;
        font-size: 22px;
      }
    }
  }
}
