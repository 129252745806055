.bookingContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  color: black;
  .divider {
    height: 175px;
  }
  .bookingSection {
    display: flex;
    width: 100%;
    position: static;
    h5 {
      font-weight: 700;
    }
    img {
      width: 40%;
    }
  }
}
.bookingInfo {
  background-color: white;
  padding: 32px;
  a {
    color: #e6c200;
  }
  h1 {
    font-family: 'Comorant', serif;
    font-weight: 300;
    font-size: 4em;
    line-height: 1.2em;
    max-width: 650px;
  }
}
.bookingBreakdown {
  width: 50%;
  p {
    text-align: center;
  }
  button {
    width: 100%;
    margin-top: 34px;
  }
  h2 {
    font-weight: 600;
    color: rgba(185, 184, 184, 0.7);
    font-size: 1.25em;
  }
}
.healerInfo {
  position: relative;
  max-width: 70%;
  .bg {
    width: 50%;
    height: 100%;
    background-image: url('/assets/greenBg.jpg');
    background-size: contain;
    position: fixed;
    .healerImg {
      z-index: 999;
      text-align: center;
      margin: 35% auto;
      color: white;
      img {
        width: 20%;
      }
    }
  }
}
.flexRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bookingDetails {
  width: 85%;
}
.healerBookingDetails {
  background-color: white;

  .details {
    width: 50%;
    margin-left: 30%;
    padding-top: 60px;
    img {
      width: 100%;
    }
    h1 {
      font-size: 24px;
    }
    h2 {
      color: #d1cece;
    }
  }
}
.bookingColor {
  background-color: #f5f5f5;
  h1 {
    font-size: 3rem;
  }
  textarea {
    padding: 16px 0 0 16px;
  }
}
