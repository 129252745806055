.landing {
  .workSection {
    background-color: black;
    color: white;
  }
}
.workSection-title {
  width: 90%;
  .landing-title {
    font-family: 'Comorant', serif;
    font-size: 42px;
    font-weight: 200;
  }
}

.mini-box {
  cursor: pointer;
  height: 100%;
  line-height: 30px;
  width: 100%;
  padding-bottom: 24px;
  &:hover {
    opacity: 0.9;
    transition: all ease-in-out 0.4s;
  }
  &.push-top {
    padding-top: 24px;
  }
  h4 {
    z-index: 99;
    position: absolute;
    font-size: 22px;
    line-height: 28px;
    bottom: 40px;
    margin-left: 18px;
    color: white;
    width: 75%;
  }
}
.black-bg {
  background-color: black;
  height: 450px;
  width: 100%;
  margin-top: -20%;
}
.test-box {
  h1 {
    font-family: 'Comorant', serif;
    font-size: 60px;
    line-height: 65px;
    font-weight: 200;
    width: 75%;
    color: black;
  }
  .test-box-name {
    margin-top: -15%;
    h4 {
      font-size: 22px;
      line-height: 12px;
      color: white;
    }
    h3 {
      font-size: 16px;
      font-weight: 100;
    }
  }
  p {
    width: 80%;
    padding-top: 24px;
  }
}

.relativeIndex {
  position: relative;
  z-index: 1;
}
